import React from "react"
import Wrapper from "../atoms/Wrapper"

const Art: React.FC = () => {
	return (
		<Wrapper>
			<p>
				text 2153850579.
				<br />
				marketplace coming soon.
			</p>
		</Wrapper>
	)
}

export default Art
