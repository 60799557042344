import React from "react"
import Root from "./Root"
import Theme from "./Theme"

// createRoot is used to create the ___ from the root constant we create that
// holds the element with id root (from root.tsx)
import { createRoot } from "react-dom/client"

//React query gives hooks for fetching/caching/updating async data in react
import { QueryClient, QueryClientProvider } from "react-query"

//RouterProvider is the component that will be rendered at the root route to
//pass along the router constant created by createBrowserRouter
//createBrowserRouter is used to create routing parameters for a Root path &
//it's children, you can render different components/pages at different paths
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import BookNotes from "./pages/BookNotes"
import Projects from "./pages/Projects"
import Quotes from "./pages/Quotes"
import Gazettes from "./pages/Gazettes"
import Jawns from "./pages/Jawns"
import Writings from "./pages/Writings"
import EntryPage from "./pages/EntryPage"
import Secrets from "./pages/Secrets"
import WordCandy from "./pages/WordCandy"
import Sandbox from "./pages/Sandbox"
import AdminPage from "./pages/Admin"
import Art from "./pages/Art"

//function of the index.tsx for holding all of the site's routing parameters &
//directing it to the relevant pages for what url needs to render what code also
//holds any logic the site will need to send/retrieve(like authorization, api
//interactions, http requests, etc.)

/*this solves the error message "Type 'typeof QueryClient' is missing the
following properties from type 'QueryClient': queryCache, mutationCache,
defaultOptions, queryDefaults, and 34 more.ts(2740) Did you mean to use 'new'
with this expression?" what else does it do?*/
const queryClient = new QueryClient()

//this allows us to use express routing parameters, can render different tsx
//variables according to what element we use, it defines the <Root/> as the home
//element
const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/about",
				element: <About />,
			},
			{
				path: "/art",
				element: <Art />,
			},
			{
				path: "/booknotes",
				element: <BookNotes />,
			},
			{
				path: "/projects",
				element: <Projects />,
			},
			{
				path: "/quotes",
				element: <Quotes />,
			},
			{
				path: "/gazette",
				element: <Gazettes />,
			},
			{
				path: "/jawns",
				element: <Jawns />,
			},
			{
				path: "/writing",
				element: <Writings />,
			},
			{
				path: "/:slug",
				element: <EntryPage />,
			},
			{
				path: "/secrets",
				element: <Secrets />,
			},
			{
				path: "/wordcandy",
				element: <WordCandy />,
			},
			{
				path: "/sandbox",
				element: <Sandbox />,
			},
			{
				path: "/admin",
				element: <AdminPage />,
			},
		],
	},
])

// this creates a constant in this file that holds the element with id root,
// what element will have this id of root?
const rootElement = document.getElementById("root")

//this if there's no root element, say so
if (!rootElement) throw new Error("failed to find root element")

// create a const called root that does what to the rootElement? this comes from
// the react-dom library, & replaces reactDOM.render
const root = createRoot(rootElement)
root.render(
	/*what's happening with React.StrictMode? & queryclient & routeProvider
        RouteProvider looks like a react component with a key of router that is
        meant to load the constant created by createBrowserRouter RouteProvider
        & createBrowserRouter are both provided by react-router-dom
    */

	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Theme>
				<RouterProvider router={router} />
			</Theme>
		</QueryClientProvider>
	</React.StrictMode>,
)
export {}
